<template>
  <div>
    <base-section
      id="portal"
      class="lightgrey"
      space="0"
    >
      <div class="col s12">
        <v-card>
          <v-card-title>
            <span class="headline">{{ thisuser.name }} ({{ thisuser.email }})</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-dialog
                    v-model="dialog"
                    max-width="500px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Change Password
                      </v-btn>
                    </template>
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                      @submit.prevent="saveNewPassword"
                    >
                      <v-card>
                        <v-card-title>
                          <span class="headline">New Password</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col
                                cols="12"
                              >
                                <v-text-field
                                  v-model="newPassword"
                                  :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'"
                                  :rules="[rules.required, rules.min, rules.complex]"
                                  :type="showPw ? 'text' : 'password'"
                                  name="input-10-1"
                                  label="New Password"
                                  counter
                                  @click:append="showPw = !showPw"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="closeNewPassword"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="saveNewPassword"
                          >
                            Update
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="thisuser.name"
                    label="Full Name"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="thisuser.phone"
                    label="Phone #"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            
            <v-btn
              color="blue darken-1"
              text
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>

      </div>      
      <v-snackbar
        v-model="snackbar"
      >
        {{ snackbarMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </base-section>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Users } from '@/database/users'
  import { FirebaseResults, Auth } from '@/database'

  export default {
    name: 'SectionMap',
    data: () => ({
      dialog: false,
      userid: '',
      thisuser: {},
      loadedUser: {},
      newPassword: '',
      snackbar: false,
      snackbarMessage: '',
      valid: true,
      showPw: false,
      rules: {
        required: v => !!v || 'Password is required',
        min: v => (v && v.length >= 8) || 'Name must be at least 8 characters',
        complex: v => (/[A-Z]/.test(v) && /[a-z]/.test(v) && /[0-9]/.test(v) && /[#?!@$%^&*-]/.test(v)) || 'Password Must Contain at least 1 Uppercase, 1 Lowercase, 1 Number, and 1 Special Character',
      },
    }),
    provide: {
      heading: { align: 'center' },
    },
    computed: {
      // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: 'user',
      }),
    },
    async mounted () {
      this.userid = this.user.data.id
      await this.initialize()
    },
    methods: {
      async initialize () {
        Users.findOne(this.userid).then(doc => {
          this.thisuser = doc.data()
          this.thisuser.id = doc.id
          // console.log(this.thisuser)
          // By User Type, Load the List of Deals
          // this.loadedUser = this.thisuser
        })
      },
      runtest () {
        console.log(this.acceptedDealDocs)
      },
      validate () {
        this.$refs.form.validate()
      },
      save () {
        Users.update(this.userid, this.thisuser)
          .then(() => {
            this.snackbarMessage = 'User Saved.'
            this.snackbar = true
            this.initialize()
          })
          .catch(error => {
            console.log(error)
          })
      },
      closeNewPassword () {
        this.dialog = false
        // this.newPassword = ''
      },

      saveNewPassword () {
        this.validate()
        if (this.valid) {
          Auth.currentUser.updatePassword(this.newPassword).then(() => { 
            this.snackbarMessage = 'Password Updated.'
            this.snackbar = true
            this.closeNewPassword()
          })
        }
      },
    },
  }
</script>
